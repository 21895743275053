.main {
  transition: 0.5s ease-in-out;
  width: 100%;
  padding: 2rem 4rem;
}

.upcoming-events {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 0;
  }

  &__seeall {
    color: #747688;
  }

  &__header {
    font-size: 4rem;
    font-weight: 500;
  }
}
