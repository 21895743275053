@use "../../styles/partials/variables" as *;

.upcoming-event {
  margin: 2rem 0;
  height: 25rem;
  box-shadow: 0 0.8rem 3rem rgba(80, 85, 136, 0.06);
  border-radius: 1.8rem;
  background-color: rgba(86, 105, 255, 0.1);

  &__cover-photo {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 2rem;
  }

  &__cover-photo-container {
    position: relative;
    padding: 1rem;
    height: 55%;
    border-radius: 2rem;
  }

  &__date-container {
    position: absolute;
    top: 0;
    margin-top: 2rem;
    margin-left: 2rem;
    width: 15%;
    height: 40%;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 2rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__date-day {
    color: $red;
  }

  &__date-month {
    color: $red;
  }
  &__title {
    padding: 0 1rem;
    color: #120d26;
  }

  &__location-container {
    padding: 2rem 1rem;
    display: flex;
  }

  &__location-icon {
    padding-right: 0.5rem;
  }

  &__location {
    color: #a5a7b5;
  }
}
