@use "./variables" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  max-width: 100%;
}
body {
  font-size: 1.6rem;
  padding: 4rem;
  font-family: $font, "Courier New", monospace;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}
