@use "../../styles/partials/variables" as *;

.event {
  margin: 2rem 2rem;
  height: 12rem;
  box-shadow: 0.1rem 2rem 2rem 0 rgba(90, 90, 90, 0.1);
  border-radius: 1.8rem;
  background-color: rgba(86, 105, 255, 0.1);
  display: flex;
  position: relative;

  &__cover-photo {
    width: 12rem;
    height: 100%;
    padding: 1rem;
    border-radius: 1.8rem;
    object-fit: cover;
  }

  &__details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__date {
    color: $primary-blue;
  }

  &__date-relative {
    color: $red;
    text-transform: capitalize;
  }

  &__title {
    color: #120d26;
  }

  &__delete-icon {
    color: white;
    width: 4rem;
    height: 4rem;
    position: absolute;
    right: 0;
    margin: 2rem;
    z-index: 1;
  }
}
