@use "../../styles/partials/variables" as *;

.dj-genre {
  &__form {
    display: flex;
    flex-direction: column;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
  }
}
