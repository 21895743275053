@use "../../styles/partials/variables" as *;
@use "../../styles/partials/animations";

.menu {
  &__icon {
    padding-right: 1rem;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__item {
    font-size: 1rem;
  }

  &__profile-name {
    padding: 0 1.6rem;
  }

  &__profile-image {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
  }
}

.nav2 {
  background: $primary-blue;
  bottom: 0;
  box-shadow: 0 0 0.8rem #666;
  height: 100vh;
  left: -20.5rem;
  padding: 4rem 1rem;
  position: absolute;
  top: 0;
  transition: 0.5s ease-in-out;
  width: 20rem;
  user-select: none;

  & a {
    color: #eee;
    padding: 1.2rem 1.6rem;
    transition: 0.5s;
    text-decoration: none;
    -webkit-user-drag: none;

    &:hover {
      background-color: #39c;
    }
  }
}
