@use "../partials/variables" as *;

@font-face {
  font-family: AirbnbCeral;
  src: url("../../assets/fonts/AirbnbCereal-medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: AirbnbCeral;
  src: url("../../assets/fonts/AirbnbCereal-bk.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
