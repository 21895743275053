@use "../../styles/partials/variables" as *;

.profile {
  margin: 4rem 2rem;

  &__header-container {
    display: flex;
  }
  &__header {
    font-weight: 500;
    font-size: 4rem;
  }

  &__back {
    width: 5rem;
    height: 5rem;
    cursor: pointer;
    margin-right: 1rem;
  }

  &__details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10rem;
  }

  &__image {
    width: 9.6rem;
    height: 9.6rem;
    border-radius: 9.6rem;
    margin: 2rem 0;
  }

  &__name {
    padding: 0.2rem 0;
  }
  &__dj-name-container {
    display: flex;
    align-items: center;
  }

  &__location {
    font-size: 1.6rem;
    font-weight: 500;
  }

  &__following-followers-container {
    display: flex;
    margin: 1rem 0;
  }

  &__following-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
    border-right: 0.2rem solid $grey;
  }

  &__following-count,
  &__followers-count {
    font-weight: 700;
    font-size: 2rem;
  }

  &__following-header,
  &__followers-header {
    font-weight: 400;
    font-size: 1.6rem;
  }

  &__followers-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
  }
  &__edit-button {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 40%;
    height: 5rem;
    border-radius: 1.5rem;
    border: 0.2rem solid $primary-blue;
    background: none;
    font-size: 1.6rem;
    font-weight: 400;
    color: $primary-blue;
  }

  &__more-details {
    padding: 2rem 0;
  }
  &__about-header {
    font-size: 2rem;
    font-weight: 500;
  }

  &__about-text,
  &__price {
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0.5rem 0;
  }

  &__price-header,
  &__genres-header {
    font-size: 2rem;
    font-weight: 500;
    padding-top: 2rem;
  }

  &__genres-container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
  }

  &__genre {
    margin: 2rem;
    border: 0.2rem solid $primary-blue;
    border-radius: 1.6rem;
    padding: 1rem;
    color: white;
    background: $primary-blue;
    font-size: 1.6rem;
    font-weight: 500;
    // below only works on run time. implement classes for each value
    // background: rgb(random(255), random(255), random(255));
  }
}
