.dj-list {
  margin: 4rem 0;
  padding: 2rem 0;
  &__text {
    font-size: 4rem;
    font-weight: 500;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
  }
  &__link {
    width: 50%;
  }

  &__header-container {
    display: flex;
  }

  &__back {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
    cursor: pointer;
  }
}
