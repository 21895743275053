// Typography
$font: AirbnbCeral;

// colours

$red: #f0635a;
$primary-blue: #5669ff;
$secondary-blue: #46cdfb;
$grey: #e6e6e6;
$green: #29d697;
$secondary-grey: #747688;
