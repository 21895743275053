.dj-name {
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
  }
}
