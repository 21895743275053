@use "../../styles/partials/variables" as *;

.signin {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: center;
  //   position: absolute;
  //   top: 0;

  &__logo {
    width: 50%;
    margin: 2rem;
    align-self: center;
  }
}

.signin-form {
  display: flex;
  flex-direction: column;

  &__input {
    border-radius: 1.2rem;
    border: 1px solid #e4dfdf;
    height: 5rem;
    margin: 1rem 0;
  }

  &__button {
    background: $primary-blue;
    box-shadow: 0px 10px 35px rgba(111, 126, 201, 0.25);
    border-radius: 15px;
    height: 5rem;
    margin: 1rem 0;
  }

  &__checkbox {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    & input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  &__rememberme-container {
    display: flex;
    align-items: center;
  }

  &__rememberme-text {
    padding-left: 2rem;
  }

  &__signup-container {
    display: flex;
    justify-content: center;
  }
}

input:checked + .signin-form__slider {
  background-color: #2196f3;
}

input:focus + .signin-form__slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .signin-form__slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
