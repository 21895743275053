@use "../../styles/partials/variables" as *;

.events {
  &__new-event-button-container {
    display: flex;
    justify-content: space-between;
    margin: 4rem 2rem;
  }

  &__new-event-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-blue;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    width: 30%;
    padding: 1rem;
    border-radius: 2rem;
    border-color: $primary-blue;
  }
  &__title {
    font-size: 4rem;
    font-weight: 500;
  }

  &__header-container {
    display: flex;
  }

  &__back {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
    cursor: pointer;
  }
}
