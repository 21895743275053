@use "../../styles/partials/variables" as *;

.event-details {
  margin-top: 3rem;
  position: relative;
  padding: unset;
  &__image {
    position: absolute;
    margin-top: 4rem;
    min-width: 100%;
    max-height: 20%;
    margin-left: -4rem;
    z-index: -1;
    object-fit: cover;
  }

  &__header-container {
    display: flex;
    padding-top: 9rem;
  }
  &__header {
    font-size: 4rem;
    color: white;
  }

  &__back {
    height: 5rem;
    width: 5rem;
    margin: 0 2rem 0 0;
    cursor: pointer;

    &:hover {
      width: 6rem;
      border-bottom: solid white 0.5rem;
    }
  }

  &__title {
    font-size: 5rem;
    font-weight: 400;
  }

  &__share-container {
    width: 100%;
    height: 10rem;
    margin-bottom: 4rem;
    background-color: #fefeff;
    box-shadow: 0 2rem 2rem 0 rgba(90, 90, 90, 0.1);
    backdrop-filter: blur(10.873126983642578px);
    border-radius: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: $secondary-blue;
    }
  }
  &__share-header {
    font-size: 5rem;
  }
  &__highlights-container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
  &__type-container,
  &__date-container,
  &__location-container,
  &__organiser-container {
    display: flex;
    align-items: center;
    margin: 1rem 0;
  }
  &__type-icon,
  &__date-icon,
  &__location-icon {
    padding: 1.5rem;
    background: rgba(86, 105, 255, 0.2);
    border-radius: 1.3rem;
    margin-right: 2rem;
  }

  &__type,
  &__date--short,
  &__location,
  &__organiser {
    font-size: 2rem;
    font-weight: 500;
  }

  &__date--long,
  &__organiser-header {
    font-size: 1.6rem;
    font-weight: 400;
    color: $secondary-grey;
  }
  &__profile-icon {
    width: 6rem;
    height: 6rem;
    border-radius: 1.3rem;
    margin-right: 2rem;
  }
  &__organiser-text-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__follow-button {
    background: rgba(86, 105, 255, 0.2);
    font-size: 1.6rem;
    font-weight: 500;
    color: $primary-blue;
    box-shadow: 0px 8px 20px 0px rgba(74, 210, 228, 0.08);
    border-radius: 0.7rem;
    padding: 1rem;
    border-color: rgba(74, 210, 228, 0.08);

    &:hover {
      background: rgba(86, 105, 255, 0.4);
    }
  }

  &__about-header {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  &__about {
    font-size: 2rem;
  }

  &__apply-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-blue;
    color: white;
    font-size: 2rem;
    text-transform: uppercase;
    width: 65%;
    padding: 1rem;
    border-radius: 2rem;
    border-color: $primary-blue;
  }

  &__apply-button-container {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
  }

  &__apply-icon {
    padding-left: 1rem;
  }
}
